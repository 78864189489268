import React from "react";


export function Location(props) {
  const location = props.location;

  return (
    <div className="piece location">
      <a className="link" href="https://maps.google.com/maps?q=Poland,+Warsaw">
        <div className="icon"></div>
        <div className="label">{location.label}</div>
        <div
          className="value"
          itemProp="address"
          itemScope=""
          itemType="http://schema.org/PostalAddress">
          <span itemProp="addressLocality">{location.value.locality}</span>,
          <div itemProp="addressCountry">{location.value.country}</div>
        </div>
      </a>
    </div>
  );
}
