import React from "react";


export function Email(props) {
  const email = props.email;
  return (
    <div className="piece email">
      <a className="link" href={"mailto:" + email.value}>
        <div className="icon"></div>
        <div className="label">{email.label}</div>
        <div className="value" itemProp="email">
          {email.value}
        </div>
      </a>
    </div>
  );
}
