import "./App.css";
import { profile , social, languages } from "./Data/profile.js";
import React from "react";

import { Link } from "react-router-dom";
import { Languages } from "./Components/Languages";
import { Contacts } from "./Components/Contacts";

function App(props) {
  let language = props.language;
  return (
    <div className="person" itemScope="" itemType="http://schema.org/Person">
      <Link to="/">
        <div className="images is-animated with-right-rotation">
          <div className="mask"></div>
        </div>
      </Link>
      <Contacts profile={profile[language]} social={social} />
      <div className="languages">
        <Languages languages={languages} />
      </div>
    </div>
  );
}

export default App;
