const profile = {
    en: {
        firstName: "Anton",
        lastName: "Tipunov",
        email: {
            label: "E-mail",
            value: "UnChanter@gmail.com"
        },
        location: {
            label: "Location",
            value: {
                locality: "Poland",
                country: "Warsaw"
            }
        },
        phones: [{
            label: "BY",
            value: "+375259419001",
            formatted: "+375 25 9419001"
        }, {
            label: "PL",
            value: "+48571804657",
            formatted: "+48 571 804 657",
        }]
    },
    ru: {
        firstName: "Антон",
        lastName: "Типунов",
        email: {
            label: "Эл. почта",
            value: "UnChanter@gmail.com"
        },
        location: {
            label: "Расположение",
            value: {
                locality: "Варшава",
                country: "Польша"
            }
        },
        phones: [{
            label: "BY",
            value: "+375259419001",
            formatted: "+375 25 9419001"
        }, {
            label: "PL",
            value: "+48571804657",
            formatted: "+48 571 804 657",
        }]
    }
};

const languages=  [
    {
        language: 'en',
        title: "English"
    }, {
        language: 'ru',
        title: "Русский"
    }
];

const social = [{
    className: "github",
    url: "https://github.com/atipunov",
    label: "GitHub",
    login: "atipunov"
}, {
    className: "linkedin",
    url: "https://www.linkedin.com/in/atipunov",
    label: "LinkedIn",
    login: "atipunov"
}, {
    className: "vk",
    url: "https://vk.com/tipunov1",
    label: "VK",
    login: "tipunov1"
}];

export {
    profile, social, languages
};