import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { languages } from "./Data/profile.js";

import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ErrorPage from './ErrorPage';

const router = createBrowserRouter([
  {
    path: "/",
    element: <App language={"en"} />,
    errorElement: <ErrorPage />,
  },
  ...languages.map(lang => {
    return {
      path: "/" + lang.language,
      element: <App language={lang.language} />,
      errorElement: <ErrorPage />,
    }
  })
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);