import React from "react";

export function Social(props) {
  const social = props.social;
  return (
    <div className={"piece " + social.className}>
      <a className="link" href={social.url} rel="me">
        <div className="icon"></div>
        <span className="service">{social.label}</span>
        <span className="dash"></span>
        <span className="login">{social.login}</span>
      </a>
    </div>
  );
}
