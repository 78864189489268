import React from "react";

export function Phone(props) {
  const phone = props.phone;

  return (
    <a className="link" href={"tel:" + phone.value}>
      <div className="label">{phone.label}</div>
      <div className="value" itemProp="telephone">
        {phone.formatted}
      </div>
    </a>
  );
}
