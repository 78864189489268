import React from "react";
import { Link } from "react-router-dom";

export function Languages(props) {
  const languages = props.languages; 
  const result = [];

  languages.forEach((language) => {
    result.push(
      <Link
        className="lang"
        to={"/" + language.language}
        hrefLang={language.language}
        rel="alternate"
        key={language.language}>
        <span className="text">{language.title}</span>
      </Link>
    );
  });
  
  return <div className="languages">{result}</div>;
}
