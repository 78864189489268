import React from "react";
import { Location } from "./Location";
import { Email } from "./Email";
import { Phones } from "./Phones";
import { Socials } from "./Socials";

export function Contacts(props) {
  const profile = props.profile;
  const social = props.social;
  return (
    <div className="content-position">
      <h1 className="name">
        <span itemProp="givenName">{profile.firstName} </span>
        <span itemProp="familyName"> {profile.lastName}</span>
      </h1>
      <div className="contacts list">
        <Email email={profile.email} />
        <Location location={profile.location} />
        <Phones phones={profile.phones} />
      </div>
      <Socials socials={social} />
    </div>
  );
}
