import React from "react";
import { Social } from "./Social";


export function Socials(props) {
  const socials = [];
  props.socials.forEach((social) => {
    socials.push(<Social social={social} key={social.label} />);
  });

  return <ul className="links list">{socials}</ul>;
}
