import React from "react";
import { Phone } from "./Phone";


export function Phones(props) {
  const phones = [];
  props.phones.forEach((phone) => {
    phones.push(<Phone phone={phone} key={phone.label} />);
  });

  return (
    <div className="piece phone">
      <div className="icon"></div>
      {phones}
    </div>
  );
}
